<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2">Monitoring Review per SKPD</h3>
          </template>
          <v-row>
            <v-col>
              <crud
              :crudSettings="crudSettings"
              :headers="headers"
              :responseData="{data:items}"
              :detailData="detailData"
              :forceUpdateTable="forceUpdate"
              @onTableChange="updateTable"
              >
                <template v-slot:prepend-header>
                  <v-select style="max-width:200px" :items="months" label="Bulan" hide-details class="mx-2"></v-select>
                  <v-divider class="mx-2" inset vertical/>
                </template>
              </crud>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {months} from '@/utils/lib'
import _ from 'lodash'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'SKPD', value:'skpd', sortable:false, class:'header-index-profile'},
        {text:'Jumlah Pegawai', value:'jml_pegawai', sortable:false, class:'header-index-profile'},
        {text:'Mereview Kosong', value:'mereview_ksg', sortable:false, class:'header-index-profile'},
        {text:'Direview Kosong', value:'direview_ksg', sortable:false, class:'header-index-profile'},
        {text:'Reviewer Tidak Lengkap', value:'reviewer_tdk_lkp', sortable:false, class:'header-index-profile'},
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
    }
  },

  watch:{},

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{
    months(){
      return months
    }
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){},
  },
}
</script>

<style lang="css" scoped>
</style>
